import React, { useState } from "react";
// import { ContextTheme } from "../context/ContextTheme";
import { useNavigate } from "react-router-dom";
import { Container, Form, Col, Row, Button } from "react-bootstrap";
// import Message from "../components/Message";
import { toast } from "react-toastify";
import SocialMedia from "../../components/SocialMedia";
import { createNewInfoRequest } from "../../hooks/otherFuntioins";
import { useAuthContext } from "../../hooks/useAuthContext";
// import { db } from "../firebase.confirg";
// import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const InfoPrices = () => {
	const { user } = useAuthContext();

	const [FormData, setFormData] = useState({
		pieceName: "",
		nombre: "",
		comment: "",
		telefono: "",
		email: "",
		userPic: user ? user.photoURL : "",
	});

	const { pieceName, nombre, comment, telefono, email } = FormData;

	const navigate = useNavigate();

	let formatPhoneNumber = (str) => {
		//Filter only numbers from the input
		let cleaned = ("" + str).replace(/\D/g, "");

		//Check if the input is of correct length
		let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

		if (match) {
			return "(" + match[1] + ") " + match[2] + "-" + match[3];
		}

		return null;
	};

	const onChange = (e) => {
		setFormData((prevState) => ({
			...prevState,

			[e.target.id]: e.target.value,
		}));
	};

	const sendNotification = async (e) => {
		e.preventDefault();

		toast.success(
			"Gracias por contactaenos Alguin se comunicara a la brevedad  ... !"
		);
		createNewInfoRequest(FormData, formatPhoneNumber);

		setFormData((prevState) => ({
			pieceName: "",
			nombre: "",
			comment: "",
			telefono: "",
			email: "",
		}));
		const myTimeout = setTimeout(getHome, 2000);

		function getHome() {
			clearTimeout(myTimeout);
			navigate("/");
		}
	};

	return (
		<div className='body-app-content '>
			<SocialMedia />
			<Container>
				<Row className='justify-content-md-center text-center'>
					<Col xs={12} md={6}>
						<h2>Contact Me</h2>
						<Form onSubmit={sendNotification}>
							<Form.Group>
								<Form.Label>Your Name</Form.Label>
								<Form.Control
									id='nombre'
									type='text'
									required={true}
									placeholder='Your Name Here'
									value={nombre}
									onChange={onChange}
								></Form.Control>
							</Form.Group>
							<Form.Group>
								<Form.Label>Title</Form.Label>
								<Form.Control
									id='pieceName'
									type='text'
									required={true}
									placeholder='Title o Question Here'
									value={pieceName}
									onChange={onChange}
								></Form.Control>
							</Form.Group>
							<Form.Group>
								<Form.Label>Comments</Form.Label>
								<Form.Control
									id='comment'
									as='textarea'
									required={true}
									placeholder='Add your Comment here 300 caracters'
									value={comment}
									onChange={onChange}
								></Form.Control>
							</Form.Group>
							<Form.Group>
								<Form.Label>Email</Form.Label>
								<Form.Control
									id='email'
									type='email'
									required={true}
									placeholder='micorreo@gmail.com...'
									value={email}
									onChange={onChange}
								></Form.Control>
							</Form.Group>
							<Form.Group>
								<Form.Label>Phone Number</Form.Label>
								<Form.Control
									id='telefono'
									type='text/number'
									required={true}
									maxLength='10'
									placeholder='Just Numbers with Area Code'
									value={telefono}
									onChange={onChange}
								></Form.Control>
							</Form.Group>

							<Button
								style={{ width: "100%" }}
								className='btn'
								type='submit'
								variant='primary'
							>
								Send
							</Button>
						</Form>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default InfoPrices;

// ***** irs backup code ************
//     IDME - UE3S - VILH - RSI3
// ****** irs backup code ************
