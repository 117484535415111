import { useState } from "react";
import Select from "react-select";

import { useAuthContext } from "../../hooks/useAuthContext";
import { useFirestore } from "../../hooks/useFirestore";
import { useNavigate } from "react-router-dom";
import { Container, Button, Col, Form, Row } from "react-bootstrap";
import { categories } from "../../data/data";

export default function Create() {
	const navigate = useNavigate();
	const { addDocument, response } = useFirestore("projects");
	const { user } = useAuthContext();

	// form field values
	const [name, setName] = useState("");
	const [year, setYear] = useState("2024");
	const [details, setDetails] = useState("");
	const [category, setCategory] = useState("");
	const [formError, setFormError] = useState(null);
	const [thumbnail, setThumbnail] = useState(null);
	const [thumbnailError, setThumbnailError] = useState(null);

	const handleFileChange = (e) => {
		setThumbnail(null);
		let selected = e.target.files[0];
		console.log(selected);
		if (!selected) {
			setThumbnailError("Please select a file");
			return;
		}
		if (!selected.type.includes("image")) {
			setThumbnailError("Selected file must be and image Moron!");
			return;
		}
		if (selected.size > 3000000) {
			setThumbnailError("Image file size must be less than 3000kb");
			return;
		}
		setThumbnailError(null);
		setThumbnail(selected);
		console.log("logo updated");
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setFormError(null);

		if (!category) {
			setFormError("Please select a project category.");
			return;
		}

		const createdBy = {
			displayName: user.displayName,
			photoURL: user.photoURL,
			id: user.uid,
		};

		const project = {
			name,
			year,
			details,
			category: category.value,
			createdBy,
			comments: [],
		};

		await addDocument(project, user, thumbnail);
		if (!response.error) {
			navigate("/gallery");
		}
	};

	return (
		<div className='body-app-content form-content'>
			<h2>Add Piece to Gallery</h2>
			<Container>
				<Row className='justify-content-md-center text-center'>
					<Col xs={12} md={6}>
						<Form onSubmit={handleSubmit} className='login-form'>
							<Form.Group>
								<Row>
									<Col md={8}>
										<Form.Label>Piece Name</Form.Label>
										<Form.Control
											type='text'
											required
											placeholder='Name of the Art Piece'
											value={name}
											onChange={(e) => setName(e.target.value)}
										></Form.Control>
									</Col>
									<Col md={4}>
										<Form.Label>Year Created</Form.Label>
										<Form.Control
											type='text'
											required
											value={year}
											onChange={(e) => setYear(e.target.value)}
										></Form.Control>
									</Col>
								</Row>
							</Form.Group>
							<Form.Label>Piece Details</Form.Label>
							<Form.Control
								style={{ height: "6em" }}
								as='textarea'
								required
								placeholder='All description about this Piece'
								value={details}
								onChange={(e) => setDetails(e.target.value)}
							></Form.Control>

							<Form.Group>
								<Row>
									<Col md={6}>
										<Form.Label>Categoria</Form.Label>
										<Select
											onChange={(option) => setCategory(option)}
											options={categories}
										/>
									</Col>
									<Col md={6}>
										<Form.Label>Add Image</Form.Label>
										<Form.Control
											type='file'
											required
											onChange={handleFileChange}
										>
											{thumbnailError && (
												<div className='error'>{thumbnailError}</div>
											)}
										</Form.Control>
									</Col>
								</Row>
							</Form.Group>

							<Button type='submit' className='btn' style={{ width: "100%" }}>
								Add Project
							</Button>

							{formError && <p className='error'>{formError}</p>}
						</Form>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
