// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import {
	getAuth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
} from "firebase/auth";
import {
	getFirestore,
	serverTimestamp,
	Timestamp,
	collection,
	addDoc,
	setDoc,
	updateDoc,
	doc,
	deleteDoc,
	onSnapshot,
	query,
	where,
	orderBy,
} from "firebase/firestore";

import {
	getStorage,
	ref,
	uploadBytes,
	getDownloadURL,
	deleteObject,
} from "firebase/storage";

// const firebaseConfig = {
// 	apiKey: "AIzaSyAnXUt-YzzJHU1y3LJFfi3gV0vNznuR3bw",
// 	authDomain: "my-money-v9.firebaseapp.com",
// 	projectId: "my-money-v9",
// 	storageBucket: "my-money-v9.firebasestorage.app",
// 	messagingSenderId: "1078975193520",
// 	appId: "1:1078975193520:web:b1e5def749b1fe98cef675",
// };

const firebaseConfig = {
	apiKey: "AIzaSyACIVJ3S6O3lescDZsI6qTkXimYPXRkQHY",
	authDomain: "friedagallery.firebaseapp.com",
	databaseURL: "https://friedagallery-default-rtdb.firebaseio.com",
	projectId: "friedagallery",
	storageBucket: "friedagallery.appspot.com",
	messagingSenderId: "738617010581",
	appId: "1:738617010581:web:6a7d8ab35671d93f6eee9c",
	measurementId: "G-K3P7BXRVJ9",
};

// Initialize Firebase
initializeApp(firebaseConfig);

// init service
const auth = getAuth();
const db = getFirestore();
const storage = getStorage();

export {
	auth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile,
	db,
	collection,
	addDoc,
	setDoc,
	updateDoc,
	doc,
	deleteDoc,
	onSnapshot,
	where,
	query,
	orderBy,
	serverTimestamp,
	Timestamp,
	storage,
	ref,
	uploadBytes,
	getDownloadURL,
	deleteObject,
};
