import { useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { MdVisibility } from "react-icons/md";
import { FaUserPlus } from "react-icons/fa";

export default function Login() {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const { login, error, isPending } = useLogin();
	const [showPassword, setShowPassword] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		login(email, password);
		navigate("/");
	};

	return (
		<div className='body-app-content form-content'>
			<Container>
				<Row className='justify-content-md-center text-center'>
					<Col xs={12} md={6}>
						<h2>login</h2>
						<Form onSubmit={handleSubmit}>
							<Form.Group>
								<Form.Label>Email</Form.Label>
								<Form.Control
									// id='email'
									type='email'
									autoComplete='off'
									required={true}
									placeholder='micorreo@gmail.com...'
									value={email}
									// onChange={onChange}
									onChange={(e) => setEmail(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3'>
								<Form.Label>Password</Form.Label>
								<Form.Control
									id='password'
									value={password}
									type={showPassword ? "text" : "password"}
									placeholder='Password'
									// onChange={onChange}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<div style={{ textAlign: "end" }}>
									<MdVisibility
										onClick={() => setShowPassword((prevState) => !prevState)}
										fill='green'
										style={{
											height: "1.5em",
											width: "1.5em",
											cursor: "pointer",
										}}
									/>
									{"  "}
									ver Password
								</div>
							</Form.Group>
							{!isPending && (
								<Button
									className='btn '
									style={{ width: "100%" }}
									type='submit'
								>
									Enter
								</Button>
							)}
							{isPending && <Button disabled>loading</Button>}
							{error && <p>{error}</p>}
						</Form>
						<div
							style={{
								padding: "2em 0 2em 0",
								textAlign: "center",
							}}
						>
							<Link
								style={{ color: "gray" }}
								className='signinforgatPasswordLink'
								to='/signup'
							>
								No tienes cuenta ? registrate... <FaUserPlus />
							</Link>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
