import { useCollection } from "../../hooks/useCollection";
import { Navbar, Nav, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import "./Home.css";

export default function Home() {
	const { documents, error } = useCollection("projects");

	const [randomItem, setRandomItem] = useState(null);

	useEffect(() => {
		const randomIndex = Math.floor(Math.random() * documents.length);
		setRandomItem(documents[randomIndex]);
	}, [documents]);

	return (
		<Row className='body-app-content'>
			<Col className='sidebar-l' md={2} sm={12}>
				<Navbar>
					<Nav className='justify-content-center '>
						<Nav.Link href='/gallery'>Gallery</Nav.Link>
						<Nav.Link href='/about'>About</Nav.Link>
						<Nav.Link href='/contact'>Contact Me</Nav.Link>
					</Nav>
				</Navbar>
			</Col>
			<Col className='contenido' md={8} sm={12}>
				<main>
					{!randomItem && <div> Loading....</div>}
					{randomItem && (
						<>
							<div>
								<h2>Welcome </h2>
								<div>
									<img
										className='home-img'
										src={randomItem.photoURL}
										alt='Gia'
									/>
								</div>
							</div>
						</>
					)}
					{error && <p className='error'>{error}</p>}
				</main>
			</Col>
			<Col className='sidebar-r' md={2} sm={12}></Col>
		</Row>
	);
}
