import { useState, useEffect } from "react";
import {
	auth,
	signInWithEmailAndPassword,
	updateDoc,
	doc,
	db,
} from "../firebase/config";
import { useAuthContext } from "./useAuthContext";

export const useLogin = () => {
	const [isCancelled, setIsCancelled] = useState(false);
	const [error, setError] = useState(null);
	const [isPending, setIsPending] = useState(false);
	const { dispatch } = useAuthContext();

	const login = async (email, password) => {
		setError(null);
		setIsPending(true);

		try {
			// login
			const res = await signInWithEmailAndPassword(auth, email, password);

			// update online status
			await updateDoc(doc(db, "users", res.user.uid), {
				online: true,
			});

			// dispatch login action
			dispatch({ type: "LOGIN", payload: res.user });

			if (!isCancelled) {
				setIsPending(false);
				setError(null);
			}
		} catch (err) {
			if (!isCancelled) {
				setError(err.message);
				setIsPending(false);
			}
		}
	};

	useEffect(() => {
		setIsCancelled(false);
		return () => setIsCancelled(true);
	}, []);

	return { login, isPending, error };
};
