import { Col, Row } from "react-bootstrap";
import GradPic from "../../images/IMG_5830.PNG";
import ChiExp from "../../images/IMG_5461.JPG";
import Pic1 from "../../images/pic1-1.png";
import Pic2 from "../../images/pic2-1.png";
import Pic4 from "../../images/pic4-1.png";
import Pic5 from "../../images/pic5-1.png";
import "./About.css";

export default function Home() {
	return (
		<Row className='body-app-content'>
			<Col className='sidebar-l-about' md={4} sm={12}>
				<div>
					<h3>ARTIST STATEMENT</h3>
					<p>
						My work investigates the boundaries between the physical and the
						spiritual. I explore both figuration and abstraction and like to mix
						the two. Through self portraits and figure paintings, I make sense
						of the physical aspects of the human world. Abstraction comes in
						through color and shape.
					</p>
					<p>
						Recurring themes in my paintings are the subconscious, gender and
						cultural identity, and emotions.
					</p>

					<p>
						Painting is a way for me to reflect on myself and the world around
						me and respond to it using my point of view. Including my specific
						point of view as an individual makes the art personal to me while
						also serving as a way to reach out trying to relate to others. I
						enjoy representing the duality of things such as: brain vs body,
						self vs others, and real world vs fantasy.
					</p>
					<p>
						Energy and aggression are something that are prominent in my
						pictorial language. When painting, I am not bothered by
						irregularities, but try to create an active application that pushes
						marks. I like to explore color relationships and to push the
						boundaries of color to an almost psychedelic extent.
					</p>

					<h3>EDUCATION</h3>

					<h2>School of the Art Institute of Chicago, Chicago, Illinois </h2>
					<p>August 2019-Ongoing Expected Graduation -May 2023 with a BFA </p>
					<h2>Nerinx Hall High School, Webster Groves, Missouri </h2>
					<p>
						August 2015 - May 2019 National Honors Society Student Advisory
						Board{" "}
					</p>
				</div>
			</Col>
			<Col className='contenido-about' md={4} sm={12}>
				<img className='about-img' src={Pic5} alt='Graduation day' />
				<div style={{ display: "flex", justifyContent: "space-evenly" }}>
					<div>
						<img style={{ width: "9em" }} src={Pic1} alt='Graduation day' />
					</div>
					<div>
						<img style={{ width: "9em" }} src={Pic2} alt='Graduation day' />
					</div>
				</div>
				<div>
					<img
						style={{ width: "14em", margin: "1em 0" }}
						src={Pic4}
						alt='Graduation day'
					/>
				</div>

				<div>
					<img
						style={{ width: "10em", margin: "1em 0" }}
						src={GradPic}
						alt='Graduation day'
					/>
				</div>
				<img className='about-img' src={ChiExp} alt='Graduation day' />
			</Col>
			<Col className='sidebar-r-about' md={4} sm={12}>
				<h3>EXHIBITIONS AND AWARDS</h3>
				<span>
					<ul>
						<li className='side-li'>
							William and Elaine Small Award- Saint Louis Artists Guild, 2019.
							-2019
						</li>

						<li className='side-li'>
							Emerson Young Artists’ Showcase -Reflective Spirit Award-Nerinx
							Hall, 2019.
						</li>
						<li className='side-li'>SAIC UnderGraduate Galleries, 2023</li>
						<li className='side-li'>
							Art of Darkness Soulard Art Gallery, 2023
						</li>
						<li className='side-li'>
							Everything is a Remix, Gallery Row, 2024
						</li>
						<li className='side-li'>Window to the Soul, Art STL, 2024</li>
						<li className='side-li'>
							Pure Enjoyment Creamery Arts Center, 2024
						</li>
						<li className='side-li'>
							62nd Mid-States Art Exhibition, Evansville Museum, 2024
						</li>
					</ul>
				</span>
			</Col>
		</Row>
	);
}
