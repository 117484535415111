import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import "./SocialMedia.css";

const SocialMedia = () => {
	return (
		<section className='redes-sociales'>
			<div className='contenedor'>
				<a
					className='facebook'
					rel='noopener noreferrer'
					href='https://www.facebook.com/profile.php?id=100083307700773'
					target='_blank'
				>
					{/* <i className='fab fa-facebook-square'></i> */}
					<FaFacebookSquare />
				</a>

				<a
					className='instagram'
					rel='noopener noreferrer'
					href='https://www.instagram.com/friedagallery.com2/'
					target='_blank'
				>
					<i className='fab fa-instagram-square'></i>
					<FaSquareInstagram />
				</a>
				<a
					className='linkedln'
					rel='noopener noreferrer'
					href='#!'
					//   target='_blank'
				>
					{/* <i className='fab fa-linkedin'></i> */}
					<FaLinkedin />
				</a>
			</div>
		</section>
	);
};

export default SocialMedia;
