import { Button, Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useFirestore } from "../../hooks/useFirestore";
import { useNavigate } from "react-router-dom";

import "./GalleryDetails.css";

export default function GalleryDetails() {
	const location = useLocation();
	const Piece = location.state;
	const { user } = useAuthContext();
	const navigate = useNavigate();
	const { deleteDocument, deleteImg } = useFirestore("projects");
	const delePiece = () => {
		deleteDocument(Piece.id);
		deleteImg(Piece);
		navigate("/gallery");
	};
	const ADMIN = process.env.REACT_APP_ADMIN_USER;

	return (
		<div className='body-app-content'>
			<div className='galleryDetailsBack'>
				<Button onClick={() => navigate("/gallery")}>
					<IoChevronBackCircleSharp
						style={{ fontSize: "1.5em", padding: "2px" }}
					/>
					back
				</Button>
			</div>
			<Row className='GalletyDetails-content'>
				<Col md={8}>
					<img
						className='GalletyDetails-img'
						src={Piece.photoURL}
						alt={Piece.name}
					/>
				</Col>
				<Col md={4}>
					<h4>{Piece.name}</h4>
					<p>{Piece.year}</p>
					<hr />
					<p>{Piece.details}</p>
					{user && user.uid === ADMIN && (
						<MdDeleteForever className='detailsIcon' onClick={delePiece} />
					)}
				</Col>
			</Row>
		</div>
	);
}
