import { useState } from "react";
import { useSignup } from "../../hooks/useSignup";
import { useNavigate } from "react-router-dom";
import { Button, Form, Container, Row, Col } from "react-bootstrap";

export default function Signup() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [displayName, setDisplayName] = useState("");
	const { signup, isPending, error } = useSignup();
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		signup(email, password, displayName);
		navigate("/");
	};

	return (
		<div className='body-app-content form-content'>
			<Container>
				<Row className='justify-content-md-center text-center'>
					<Col xs={12} md={6}>
						<h2>Sign Up</h2>
						<Form onSubmit={handleSubmit}>
							<Form.Group>
								<Form.Label>Email</Form.Label>
								<Form.Control
									// id='email'
									type='email'
									autoComplete='off'
									required={true}
									placeholder='micorreo@gmail.com...'
									value={email}
									// onChange={onChange}
									onChange={(e) => setEmail(e.target.value)}
								></Form.Control>
							</Form.Group>
							<Form.Group className='mb-3'>
								<Form.Label>Password</Form.Label>
								<Form.Control
									id='password'
									value={password}
									type='password'
									placeholder='Password'
									// onChange={onChange}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Display Name</Form.Label>
								<Form.Control
									// id='email'
									type='text'
									autoComplete='off'
									required={true}
									placeholder='Your Online Name'
									value={displayName}
									// onChange={onChange}
									onChange={(e) => setDisplayName(e.target.value)}
								></Form.Control>
							</Form.Group>
							{!isPending && (
								<Button className='btn' style={{ width: "100%" }} type='submit'>
									Sign Up
								</Button>
							)}
							{isPending && <Button disabled>loading</Button>}
							{error && <p>{error}</p>}
						</Form>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
