import { useEffect, useState, useRef } from "react";
import {
	db,
	onSnapshot,
	collection,
	where,
	query,
	orderBy,
} from "../firebase/config";

export const useCollection = (namecollection, _query, _orderBy) => {
	const [documents, setDocuments] = useState([]);
	const [error, setError] = useState(null);

	// if we don't use a ref --> infinite loop in useEffect
	// _query is an array and is "different" on every function call
	const qr = useRef(_query).current;
	const ob = useRef(_orderBy).current;

	useEffect(() => {
		let ref = query(collection(db, namecollection));

		if (qr) {
			ref = query(ref, where("uid", "==", qr));
		}
		if (ob) {
			ref = query(ref, where("uid", "==", qr), orderBy(...ob));
		}

		// const q = query(ref, where("uid", "==", "VFJQpuGnkHdscKuLAmB9SiCTo6M2"));
		const unsubscribe = onSnapshot(
			ref,
			(snapshot) => {
				let results = [];
				snapshot.docs.forEach((doc) => {
					// console.log(doc);
					results.push({ ...doc.data(), id: doc.id });
				});

				// update state
				setDocuments(results);
				setError(null);
			},
			(error) => {
				console.log(error);
				setError("could not fetch the data");
			}
		);

		// unsubscribe on unmount
		return () => unsubscribe();
	}, [namecollection, qr, ob]);

	return { documents, error };
};
