import {
	addDoc,
	collection,
	doc,
	deleteDoc,
	updateDoc,
	serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { db } from "../firebase/config";
import { toast } from "react-toastify";

const storage = getStorage();

export const createNewInfoRequest = async (FormData, formatPhoneNumber) => {
	const { nombre, pieceName, telefono } = FormData;
	const formDataCopy = {
		...FormData,
		status: 1,
		telefono: formatPhoneNumber(telefono),
		nombre: nombre.toUpperCase(),
		pieceName: pieceName.toUpperCase(),
		timestamp: serverTimestamp(),
	};
	await addDoc(collection(db, "infoRequest"), formDataCopy);
};

export const updateNewInfoRequest = async (id, setShow) => {
	setShow(false);
	const docRef = doc(db, "infoRequest", id);

	await updateDoc(docRef, {
		status: 2,
		timestamp: serverTimestamp(),
	})
		.then(() => {
			console.log("Info request Recivido y Archivado...");
			// toast("Info request Recivido y Archivado...");
		})
		.catch((err) => {
			toast.error("No se pudo Actualizar la Info ...", err);
		});
};

export const delNewInfoRequest = async (newCatId) => {
	await deleteDoc(doc(db, "infoRequest", newCatId));
	toast("Infomacion del Cliente Borrada ...");
};

export const delPic = (userOldPic) => {
	const desertRef = ref(storage, `galleryImages/${userOldPic}`);

	if (userOldPic === "") {
		toast("No exite foto de perfil");
	} else {
		try {
			deleteObject(desertRef);
			toast("Borrando foto de Galeria");
		} catch (error) {
			toast.error("no se pudo borrar la foto..");
		}
	}
};

export const delFotogaleria = async (catId) => {
	deleteDoc(doc(db, "fotosgaleria", catId));
};
