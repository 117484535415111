import { FcCopyright } from "react-icons/fc";
import { AiOutlineTrademark } from "react-icons/ai";
import "./Footer.css";

export default function Footer() {
	return (
		<footer>
			<p className='footerP'>
				<FcCopyright /> {new Date().getFullYear()} FRIEDA SILVA
			</p>
			<p className='footerP2'>Saint Louis, MO | All Right Reserved | Privacy</p>
			<p className='footerP3'>
				<AiOutlineTrademark />
				Designed by Salvador Silva
			</p>
		</footer>
	);
}
