import { Nav, Container, Navbar, Dropdown } from "react-bootstrap";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import "./Header.css";

export default function Header() {
	const { logout } = useLogout();
	const { user } = useAuthContext();
	const ADMIN = process.env.REACT_APP_ADMIN_USER;

	return (
		<header>
			<Navbar expand='md'>
				<Container fluid>
					<div className='header-brand'>
						<Navbar.Brand href='/'>FRIEDA SILVA</Navbar.Brand>
					</div>
					<div>
						{user ? (
							<Dropdown>
								<Dropdown.Toggle
									variant='success'
									id='dropdown-basic'
									style={{ fontSize: ".7em", marginRight: "3.7em" }}
								>
									{user.displayName}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{user.uid === ADMIN && (
										<Dropdown.Item href='/create'>Add Pieces</Dropdown.Item>
									)}

									<Dropdown.Item onClick={logout} href='#'>
										Log Out
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						) : (
							<Nav.Link className='header-login' href='/Login'>
								Login
							</Nav.Link>
						)}
					</div>
				</Container>
			</Navbar>
		</header>
	);
}
